<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker
                v-model="filter.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <el-select size="small" v-model="filter.status_filter" placeholder="Select">
                <el-option
                  v-for="item in statusFilterOptions"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select> &nbsp;
              <el-button :loading="loading.table" @click="page = 1; getReportList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
              <el-button :loading="loading.table" @click="handlerClickDownloadBtn();" size="small" class="btn-primary" icon="el-icon-download">Download</el-button>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Recipient</th>
                    <th>Category</th>
                    <th>Source of Fund</th>
                    <th>Price</th>
                    <!-- <th>Balance</th> -->
                    <th>Message ID</th>
                    <th>Status</th>
                    <th>Ref. Number</th>
                  </tr>
                </thead>
                <tbody v-loading="loading.table">
                  <tr v-for="data in list" :key="data.id">
                    <td>{{ data.started_at_str }}</td>
                    <td>{{ data.ended_at_str }}</td>
                    <td>{{ data.recipient_raw }}</td>
                    <td>{{ data.pricing_category_str }}</td>
                    <td>{{ data.sof_str }}</td>
                    <td>{{ data.price_str }}</td>
                    <!-- <td>{{ data.balance_after_str }}</td> -->
                    <td>{{ data.msg_id }}</td>
                    <td>
                      <el-tag size="mini" :type="data.status_color">
                        {{ data.status_str }}
                      </el-tag>
                    </td>
                    <td>{{ data.refference_number }}</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination v-if="count > per_page" v-model="currentPage" :total-rows="count" :per-page="per_page"/>
              <el-empty v-if="!count" description="No data"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal centered v-model="show_modal.download" :title="this.$t('general.confirmation')" hide-footer @hide="handlerHideModalDownload">
      <p class="mb-2 text-center">
        {{ this.$t('report.confirm_download') }}
        <br>
        You can choose delimiter for your export file.
      </p>
      <b-form>
        <b-form-group class="mb-4 text-center">
          <el-select v-model="filter.delimiter" size="small" class="w-30">
            <el-option value=";" label="Delimiter ;" />
            <el-option value="," label="Delimiter ," />
          </el-select>
        </b-form-group>
      </b-form>
      <div class="mt-2 text-center">
        <b-button size="sm" type="submit" @click="confirmDownload" variant="primary" class="mr-2">{{ $t('general.yes') }}</b-button>
        <b-button size="sm" type="reset" @click="handlerHideModalDownload" variant="secondary">{{ $t('general.no') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'ReportSessionDetail',
  metaInfo: {
    title: 'Report Session',
  },
  data() {
    return {
      list: [],
      count: 0,
      pricing_category: {
        user_initiated: 'User Initiated',
        business_initiated: 'Business Initiated',
        service_conversation: 'Service Conversation',
        marketing_conversation: 'Marketing Conversation',
        utility_conversation: 'Utility Conversation',
        authentication_conversation: 'Authentication Conversation',
        referral_conversion: 'Referral Conversion',
        service: 'Service Conversation',
        marketing: 'Marketing Conversation',
        utility: 'Utility Conversation',
        authentication: 'Authentication Conversation',
      },
      sof: {
        balance: 'Balance',
        free_quota: 'Free WhatsApp Quota',
        free_quota_bi: 'Free WhatsApp BI',
        free_quota_ui: 'Free WhatsApp UI',
        free_quota_marketing: 'Free WhatsApp Marketing',
        free_quota_authentication: 'Free WhatsApp Authentication',
        free_quota_service: 'Free WhatsApp Service',
        free_quota_utility: 'Free WhatsApp Utility',
        postpaid: 'Postpaid',
      },
      per_page: 50,
      loading: {
        table: true,
      },
      show_modal: {
        download: false,
      },
      filter: {
        page: this.$route.query.page || 1,
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
        status_filter: this.$route.query?.status_filter || '',
        delimiter: ';',
      },
      statusFilterOptions: [
        {
          value: '',
          text: 'All',
        },
        {
          value: 'success',
          text: 'Success',
        },
        {
          value: 'failed',
          text: 'Failed',
        },
      ],
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 180 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const start = moment().subtract(6, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getReportList();
    loader.hide();
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.filter.page;
      },
      set(val) {
        this.filter.page = val;
        this.getReportList();
      },
    },
    company_id() {
      return this.$store.state.backOffice.activeCompany;
    },
    token() {
      return this.$store.state.backOffice.token;
    },
  },
  methods: {
    async getReportList() {
      const options = {
        page: this.filter.page.toString(),
        per_page: this.per_page.toString(),
        start_date: moment(this.filter.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.filter.date_range[1]).format('YYYY-MM-DD'),
        status_filter: this.filter.status_filter,
        delimiter: this.filter.delimiter,
      };
      this.loading.table = true;
      const { data } = await reportApi.sessionList({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
        query: options,
      }).catch((err) => console.log(err));
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      data.rows = data.rows.map((v) => {
        v.started_at_str = moment(v.started_at).format('DD MMM YYYY, HH:mm');
        v.ended_at_str = moment(v.ended_at).format('DD MMM YYYY, HH:mm');
        v.pricing_category_str = this.pricing_category[v.pricing_category];
        v.balance_after_str = '-';
        if (['balance', 'postpaid', 'free_credit'].includes(v.source_of_fund) && v.balance_after) {
          v.balance_after_str = v.balance_after.toLocaleString('id-ID');
          if (v.source_of_fund === 'balance') {
            v.balance_after_str = `Rp. ${v.balance_after_str}`;
          } else {
            v.balance_after_str += ' quota(s)';
          }
        }
        v.refference_number = v.refference_number.split(':')[0];
        if (['balance', 'postpaid', 'free_credit'].includes(v.source_of_fund)) {
          v.price_str = `Rp. ${(v.price || '').toLocaleString('id-ID')}`;
        } else {
          v.price_str = `${v.price} quota`;
        }
        v.sof_str = this.sof[v.source_of_fund] || '-';
        v.status_str = ['success', 'waiting'].includes(v.status) ? 'Success' : v.status.toUpperCase();
        v.status_color = ['success', 'waiting'].includes(v.status) ? 'success' : 'danger';
        return v;
      });
      ({ rows: this.list, count: this.count } = data);
      this.loading.table = false;
    },
    handlerClickDownloadBtn() {
      this.show_modal.download = true;
      this.filter.delimiter = ';';
    },
    handlerHideModalDownload() {
      this.show_modal.download = false;
    },
    async confirmDownload() {
      this.handlerHideModalDownload();
      this.loading.table = true;
      const options = {
        page: this.filter.page.toString(),
        per_page: this.per_page.toString(),
        start_date: moment(this.filter.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.filter.date_range[1]).format('YYYY-MM-DD'),
        status_filter: this.filter.status_filter,
        delimiter: this.filter.delimiter,
      };
      const response = await reportApi.downloadSessionList({
        workspace_id: this.activeWorkspace._id,
        company_id: this.company_id,
        token: this.token,
        channel_id: this.$route.params.channel_id,
        query: options,
      }).catch(() => {});
      this.loading.table = false;
      await popupErrorMessages(response);
      this.$message({
        title: this.$t('general.success'),
        type: 'success',
        message: this.$t('general.waiting_download'),
        duration: 30 * 1000,
        showClose: true,
      });
    },
    // async downloadReport() {
    //   this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
    //     confirmButtonText: this.$t('general.yes'),
    //     cancelButtonText: this.$t('general.no'),
    //     type: 'warning',
    //     center: true,
    //     /* eslint-disable no-param-reassign */
    //     beforeClose: async (action, instance, cb) => {
    //       if (action === 'confirm') {
    //         instance.confirmButtonLoading = true;
    //         this.loading.table = true;
    //         const options = {
    //           page: this.filter.page.toString(),
    //           per_page: this.per_page.toString(),
    //           start_date: moment(this.filter.date_range[0]).format('YYYY-MM-DD'),
    //           end_date: moment(this.filter.date_range[1]).format('YYYY-MM-DD'),
    //           status_filter: this.filter.status_filter,
    //         };
    //         const response = await reportApi.downloadSessionList({
    //           workspace_id: this.activeWorkspace._id,
    //           company_id: this.company_id,
    //           token: this.token,
    //           channel_id: this.$route.params.channel_id,
    //           query: options,
    //         }).catch(() => {});
    //         this.loading.table = false;
    //         instance.confirmButtonLoading = false;
    //         await popupErrorMessages(response);
    //         this.$message({
    //           title: this.$t('general.success'),
    //           type: 'success',
    //           message: this.$t('general.waiting_download'),
    //           duration: 10 * 1000,
    //           showClose: true,
    //         });
    //       }
    //       cb();
    //     },
    //   });
    // },
  },
};
</script>
